<template lang="pug">
.map-wrapper
	.map-info.sf-card
		.info-header
			svg(xmlns="http://www.w3.org/2000/svg", viewBox="0 0 574.53 113.39", aria-hidden="true")
				path(fill="#FFFFFF", d="M0 53.24C0 23.86 23.81 0.03 53.18 0v19.24c-18.75 0.03-33.93 15.24-33.93 34c0 18.78 15.22 34 34 34 c18.67 0 34-15.08 34-34.13l19.24 0.01c0 29.52-23.84 53.36-53.24 53.36C23.84 106.48 0 82.65 0 53.24")
				path(fill="#FFFFFF", d="M29.76 53.24c0-13.04 10.57-23.62 23.61-23.62c13.05 0 23.62 10.58 23.62 23.62S66.42 76.85 53.37 76.85 C40.33 76.85 29.76 66.28 29.76 53.24")
				path(fill="#FFFFFF", d="M69.75 23.33c0-7.47 6.06-13.54 13.53-13.54c7.48 0 13.53 6.07 13.53 13.54c0 7.47-6.06 13.53-13.53 13.53 C75.81 36.86 69.75 30.8 69.75 23.33")
				path(fill="#FFFFFF", d="M168.14 27.02c6.85 0 24.88 2.83 24.88 23.36c0 21.62-12.06 36.83-33.24 36.83 c-21.84 0-25.97-13.58-25.97-23.68C133.81 56.35 136.74 27.02 168.14 27.02z M160.1 75.69c11.52 0 14.88-19.88 14.88-27.7 c0-3.69-1.09-9.45-8.15-9.45c-13.04 0-14.88 24.12-14.88 27.49C151.95 73.41 155.98 75.69 160.1 75.69z")
				path(fill="#FFFFFF", d="M226.81 85.79c-4.56 1.08-8.47 1.19-9.99 1.19c-12.38 0-16.3-5.32-16.3-12.71c0-4.13 1.19-9.23 3.58-18.79 l3.7-14.99h-1.2c-2.39 0-4.13 0.11-6.41 0.22l-0.65-0.76c1.19-4.02 1.96-6.95 3.04-10.97l0.76-0.65h7.28 c1.19-5.11 2.06-8.69 3.04-13.91l0.76-0.76c6.63-1.52 11.3-2.71 17.82-4.67l0.65 0.76c-0.87 3.04-1.41 5.76-4.78 18.91 c4.13-0.11 7.06-0.11 11.19-0.33l0.43 0.87c-1.41 3.91-2.39 6.74-3.37 10.86l-0.98 0.65c-3.69-0.11-6.3-0.11-9.99-0.11l-4.56 19.01 c-1.09 4.56-2.06 8.69-2.06 10.32c0 1.96 1.19 4.45 5.43 4.45c2.82 0 4.78-0.87 5.97-1.3l0.65 0.54c-1.3 4.24-2.06 7.17-3.26 11.3 L226.81 85.79z")
				path(fill="#FFFFFF", d="M249.62 112.73c-3.91 0-5.22-0.11-7.5-0.11c-2.06 0-9.45 0.11-10.86 0.22l-0.54-0.76 c3.58-13.69 13.8-53.78 17.71-81.48l0.76-0.76c6.19-0.87 12.39-1.85 18.58-3.04l0.65 0.87l-1.3 4.78c2.28-1.41 7.39-5.32 14.78-5.32 c7.28 0 17.92 5.11 17.92 21.73c0 14.88-8.37 29.12-22.49 34.55c-6.84 2.61-15.64 2.72-18.25 2.72h-3.48 c-1.74 8.26-3.37 16.84-5.22 25.96L249.62 112.73z M258.31 74.06c1.19 0.22 2.06 0.22 2.61 0.22c1.63 0 6.19-0.43 10.32-3.26 c6.52-4.45 9.89-13.04 9.89-20.75c0-6.63-3.37-10.21-7.93-10.21c-3.37 0-5.32 1.3-7.93 3.04L258.31 74.06z")
				path(fill="#FFFFFF", d="M366.15 30.06c-3.04 12.28-5.76 23.47-8.37 35.74l-3.04 14.34c-3.8 18.03-6.95 33.24-30.75 33.24 c-6.52 0-16.3-1.52-22.38-8.15v-0.87c1.63-2.5 2.72-4.35 4.35-6.95h0.87c2.93 3.8 8.36 7.71 16.4 7.71 c16.19 0 17.49-14.99 18.69-18.69l1.09-4.45c-7.17 5-13.58 5.1-15.97 5.1c-12.82 0-19.56-9.77-19.56-21.08 c0-2.71 0.43-11.95 6.19-20.86c10.21-15.75 26.51-15.75 39-15.75c5.43 0 13.04-0.22 13.04-0.22L366.15 30.06z M352.79 37.88 c0 0-3.26-0.11-4.24-0.11c-9.78 0-20.32 1.74-26.29 14.45c-2.61 5.65-2.93 10.1-2.93 12.5c0 11.3 7.28 13.91 12.71 13.91 c5.97 0 10.32-3.04 12.71-5.32L352.79 37.88z")
				path(fill="#FFFFFF", d="M375.82 85.9c-4.24 0-7.17 0.11-11.3 0.22l-0.54-0.65c4.56-17.06 10.76-45.09 11.84-54.32l0.76-0.76 c4.35-0.54 7.5-0.98 11.73-1.85l0.54 0.76c-0.87 3.15-1.52 5.97-2.39 9.56c1.63-2.71 6.41-10.76 14.12-10.76c2.39 0 3.91 0.54 5 1.2 l0.33 0.98c-1.63 3.91-2.71 6.63-4.24 10.65l-1.09 0.33c-0.76-0.54-2.5-1.52-4.56-1.52c-2.93 0-9.67 1.52-13.04 15.64 c-0.98 4.13-5.32 22.82-6.3 29.77L375.82 85.9z")
				path(fill="#FFFFFF", d="M433.18 28.21c13.47 0 23.25 6.84 23.25 21.3c0 11.41-5.21 37.7-30.85 37.7c-6.84 0-22.6-1.63-22.6-21.73 C402.98 47.88 412.54 28.21 433.18 28.21z M425.8 79.17c16.4 0 19.12-26.51 19.12-30.2c0-10.54-6.41-12.82-11.3-12.82 c-14.99 0-19.12 22.27-19.12 30.09C414.5 73.19 417.76 79.17 425.8 79.17z")
				path(fill="#FFFFFF", d="M504.56 85.79c-3.91 0.11-6.74 0.22-10.65 0.43l-0.54-0.76l1.3-5.22c-5.65 4.67-11.95 6.73-17.71 6.73 c-13.26 0-16.19-9.01-16.19-14.88c0-3.91 0.87-8.04 2.83-16.62l2.28-10.1c1.09-4.78 1.96-8.15 3.15-14.99l0.76-0.76 c4.02 0 6.84-0.22 10.86-0.43l0.54 0.65c-4.02 16.51-8.47 33.79-8.47 38.68c0 3.15 0.87 9.34 9.34 9.34c4.89 0 9.78-2.06 14.45-6.3 c5.65-25.42 8.15-37.7 8.69-41.18l0.87-0.65c4.02-0.11 6.85-0.33 10.97-0.54l0.43 0.76c-3.37 12.71-11.08 47.8-12.17 55.19 L504.56 85.79z")
				path(fill="#FFFFFF", d="M521.94 111.97c-1.74-0.11-3.37-0.11-5.76-0.11c-1.09 0-5.32 0.11-5.76 0.11l-0.54-0.65 c5-19.88 14.34-57.14 17.71-80.28l0.76-0.76c4.35-0.54 7.28-1.09 11.63-1.85l0.54 0.76l-1.09 4.45c2.82-1.85 8.26-5.22 15.21-5.22 c13.69 0 19.88 11.08 19.88 22.16c0 4.78-2.28 35.53-40.41 35.53c-2.17 0-4.24 0-6.3-0.11c-1.96 8.36-3.48 16.73-5.22 25.31 L521.94 111.97z M529.66 77.64c0.76 0.22 2.39 0.33 3.91 0.33c23.68 0 29.12-18.91 29.12-26.4c0-11.19-7.17-14.12-12.5-14.12 c-4.13 0-8.69 1.52-12.6 5.43L529.66 77.64z")
		.info-content
			template(v-if="isMobile")
				label#countries-select-box-label.sr-only(for="countries-select-box") Please choose a country
				VueSelect#countries-select-box(label-id="countries-select-box-label", v-model="selectedBank", placeholder="Please choose a country!")
					VueSelectOption(v-for="(bank, index) in banks", :text="bank", :key="index", :index="index", :value="bank", :label-id="`countries-select-box-${index}`")
				a.sf-link( v-if="selectedWebsite", :href="selectedWebsite", target="_blank") Visit website
					svg(width="16", height="16", viewBox="0 0 16 16", fill="none", xmlns="http://www.w3.org/2000/svg")
						path(d="M6 2L4 2C2.89543 2 2 2.89543 2 4L2 12C2 13.1046 2.89543 14 4 14L12 14C13.1046 14 14 13.1046 14 12L14 10M7 9L14 2M14 2L9 2M14 2L14 7", stroke="#52AE30", stroke-linecap="round", stroke-linejoin="round")
			template(v-else)
				ul(v-if="selectedBank")
					li.sf-text-lead.sf--bold(v-for="(item, index) in banksByCountry" :key="index") {{ item.bank }}
						br
						a.sf-link(:href="item.website", target="_blank") Visit website
							svg(width="16", height="16", viewBox="0 0 16 16", fill="none", xmlns="http://www.w3.org/2000/svg")
								path(d="M6 2L4 2C2.89543 2 2 2.89543 2 4L2 12C2 13.1046 2.89543 14 4 14L12 14C13.1046 14 14 13.1046 14 12L14 10M7 9L14 2M14 2L9 2M14 2L14 7", stroke="#52AE30", stroke-linecap="round", stroke-linejoin="round")
				p.sf-text-lead.sf--bold(v-else) Please choose a country!
		p.sf-text(v-if="!(countries && countries.length)") Countries JSON cannot be found!
	.map-content(:class="selectedCountry")
		svg(width="1120", height="700", viewBox="0 0 1120 700", fill="none", xmlns="http://www.w3.org/2000/svg")
			g.country(v-for="(countryItem, index) in countries", :key="countryItem.country", :id="countryItem.country", :class="{ active: selectedCountry == countryItem.country }")
				a(href="#", @click.prevent="selectBankByCountry(countryItem.country)")
					use.pin(href="#pin", :x="countryItem.pin.default.x", :y="countryItem.pin.default.y")
					use.pin-active(href="#pin_active", :x="countryItem.pin.active.x", :y="countryItem.pin.active.y")
					text.text(:x="countryItem.text.x", :y="countryItem.text.y") {{ countryItem.country }}
			defs
				g#pin
					path(stroke="#FFF", fill="transparent", d="m13.188 32.679l0 0c-7.528 -1.548 -13.188 -8.202 -13.188 -16.179c0 -9.113 7.387 -16.5 16.5 -16.5c9.112 0 16.5 7.387 16.5 16.5c0 7.822 -5.593 15 -12.747 16.179m-7.065 0m0 0l1.945 4.934l0 0c0.561 1.423 2.575 1.423 3.135 0l1.984 -4.934")
					path(fill="#FFF", clip-rule="evenodd", fill-rule="evenodd", d="m16.39 5.581l0 3.86c-3.757 0.006 -6.8 3.059 -6.8 6.822c0 3.769 3.05 6.823 6.812 6.823c3.74 0 6.812 -3.027 6.812 -6.848l3.855 0.003c0 5.923 -4.776 10.706 -10.667 10.706c-5.891 0 -10.666 -4.783 -10.666 -10.684c0 -5.895 4.77 -10.676 10.654 -10.682z")
					path(fill="#FFF", clip-rule="evenodd", fill-rule="evenodd", d="m16.427 11.524c2.614 0 4.732 2.123 4.732 4.739c0 2.618 -2.118 4.738 -4.731 4.738c-2.613 0 -4.731 -2.12 -4.731 -4.738c0 -2.616 2.118 -4.739 4.73 -4.739z")
					path(fill="#FFF", clip-rule="evenodd", fill-rule="evenodd", d="m22.421 7.546c1.497 0 2.711 1.217 2.711 2.717c0 1.498 -1.214 2.715 -2.711 2.715c-1.497 0 -2.712 -1.217 -2.712 -2.715c0 -1.5 1.215 -2.717 2.712 -2.717z")
				g#pin_active
					path(fill="#FFF", d="m25.36901 41.545c9.501 -2.011 16.631 -10.445 16.631 -20.545c0 -11.598 -9.403 -21 -21 -21c-11.598 0 -21 9.402 -21 21c0 10.29 7.401 18.852 17.171 20.652c0.017 0.114 0.047 0.229 0.092 0.343l2.554 6.475c0.517 1.314 2.376 1.314 2.894 0l2.553 -6.475c0.059 -0.15 0.093 -0.301 0.105 -0.45z", clip-rule="evenodd", fill-rule="evenodd")
					path(fill="#006648", d="m20.85501 6.669l0 5.065c-4.93 0.009 -8.924 4.016 -8.924 8.955c0 4.946 4.003 8.955 8.94 8.955c4.909 0 8.941 -3.972 8.941 -8.988l5.06 0.004c0 7.773 -6.269 14.052 -14.001 14.052c-7.732 0 -13.999 -6.279 -13.999 -14.023c0 -7.738 6.26 -14.012 13.983 -14.02z", clip-rule="evenodd", fill-rule="evenodd")
					path(fill="#52AE30", d="m20.90501 14.469c3.431 0 6.21 2.786 6.21 6.221c0 3.435 -2.779 6.218 -6.209 6.218c-3.43 0 -6.21 -2.783 -6.21 -6.218c0 -3.435 2.78 -6.221 6.209 -6.221z", clip-rule="evenodd", fill-rule="evenodd")
					path(fill="#006648", d="m28.77201 9.248c1.965 0 3.558 1.598 3.558 3.565c0 1.968 -1.593 3.564 -3.558 3.564c-1.965 0 -3.559 -1.596 -3.559 -3.564c0 -1.967 1.594 -3.565 3.559 -3.565z", clip-rule="evenodd", fill-rule="evenodd")
				filter#filterDropShadow(filterUnits="userSpaceOnUse", color-interpolation-filters="sRGB")
					feFlood(flood-opacity="0", result="BackgroundImageFix")
					feColorMatrix(in="SourceAlpha", type="matrix", values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result="hardAlpha")
					feOffset(dy="4")
					feGaussianBlur(stdDeviation="2")
					feComposite(in2="hardAlpha", operator="out")
					feColorMatrix(type="matrix", values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0")
					feBlend(mode="normal", in2="BackgroundImageFix", result="dropShadow")
					feBlend(mode="normal", in="SourceGraphic", in2="dropShadow", result="shape")
</template>
<script>
import { computed, defineComponent, onMounted, ref } from '@vue/composition-api';
import countriesApi from '../../api/countries-api';
import { VueSelect, VueSelectOption } from '@ui-common/vue-select';
import { isMobile } from '@ui-common/sf-js-utils';
import { COUNTRIES_SVG_DATA } from './constants';

export default defineComponent({
	mixins: [isMobile],
	components: {
		VueSelect,
		VueSelectOption
	},
	props: {
		defaultCountry: {
			type: String,
			required: true
		}
	},
	setup(props) {
		const selectedBank = ref(null);
		const countries = ref([]);

		const banks = computed(() => countries.value.map((item) => item.bank));
		const selectedWebsite = computed(() => (selectedBank.value ? countries.value.find((item) => item.bank === selectedBank.value).website : null));
		const selectedCountry = computed(() => (selectedBank.value ? countries.value.find((item) => item.bank === selectedBank.value).country : null));
		const banksByCountry = computed(() => (selectedBank.value ? countries.value.filter((item) => item.country === selectedCountry.value) : null));

		const getMapData = async () => {
			try {
				const response = await countriesApi.getJson();
				countries.value = response.data.map((countryData) => {
					const svgData = COUNTRIES_SVG_DATA.find((data) => data.name == countryData.country);
					return { ...countryData, pin: svgData.pin, text: svgData.text };
				});

				selectBankByCountry(props.defaultCountry);
			} catch (error) {
				console.log('Countries JSON cannot be found!');
			}
		};
		const selectBankByCountry = (country) => {
			selectedBank.value = countries.value.find((item) => item.country === country).bank;
		};

		onMounted(() => getMapData());

		return {
			banks,
			countries,
			selectedBank,
			selectedWebsite,
			selectedCountry,
			banksByCountry,
			selectBankByCountry,
			COUNTRIES_SVG_DATA
		};
	}
});
</script>
<style src="@ui-common/vue-select/dist/vue-select.css"></style>
<style lang="scss" scoped>
@import '@ui-common/sf-style-utils/lib/helpers/_colors';
@import '@ui-common/sf-style-utils/lib/helpers/_queries';

.map-wrapper {
	border-radius: 32px;
	height: 400px;
	margin-bottom: 32px;
	margin-left: -16px;
	margin-right: -16px;
	overflow: hidden;
	position: relative;

	@include media('>=tablet') {
		height: 700px;
		margin-left: auto;
		margin-right: auto;
	}

	@include media('>=tablet', '<desktop-lg') {
		overflow-x: auto;
		overflow-y: hidden;
	}

	@include media('>=desktop-lg') {
		max-width: 1120px;
	}
}

.map-info {
	left: 8px;
	min-width: 288px;
	padding-bottom: 24px;
	position: absolute;
	text-align: center;
	top: 8px;
	z-index: 1;

	@include media('>=tablet') {
		left: 32px;
		max-width: 320px;
		top: 32px;
		width: 320px;
	}

	@include media('>=tablet', '<desktop-lg') {
		position: sticky;
	}

	& p,
	& li {
		line-height: 24px;
		margin-bottom: 0;
	}

	& li:not(:last-child) {
		margin-bottom: 16px;
	}

	& .sf-link {
		align-items: center;
		display: inline-flex;
		font-size: 14px;
		gap: 4px;
		line-height: 16px;
	}

	.sf-select-wrapper:not(:last-child) {
		margin-bottom: 16px;
	}

	.sf-select {
		@include media('>=tablet') {
			display: none;
		}
	}
}

.info-header {
	background-color: $ui-brand;
	border-radius: 24px 24px 0 0;
	margin: -32px -16px 24px;
	padding: 24px;

	@include media('>=tablet') {
		border-radius: 32px 32px 0 0;
		margin-bottom: 32px;
	}

	@include media('>=tablet', '<desktop') {
		margin: -32px -24px 32px;
	}

	@include media('>=desktop') {
		margin: -32px -32px 32px;
	}

	& svg {
		height: 24px;
		vertical-align: top;
	}
}

.map-content {
	background: url('/static/otpgroup/img/countries.png') 0 0 no-repeat;
	background-size: contain;
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 1120px;

	@include media('<tablet') {
		transition: all 0.3s;

		/* Set center position of the countries here! */
		$countries: 234 -100 albania, 152 -39 bulgaria, 316 -15 croatia, 267 54 hungary, 77 48 moldova, 262 -32 montenegro, 151 32 romania, -101 298 russia,
			224 -10 serbia, 355 33 slovenia, 3 112 ukraine, -448 2 uzbekistan;

		@each $x, $y, $country in $countries {
			$x_op_val: if($x > 0, ' + #{$x}px', ' - #{-$x}px');
			$y_op_val: if($y > 0, ' + #{$y}px', ' - #{-$y}px');

			&.#{$country} {
				left: calc(50%#{$x_op_val});
				top: calc(50%#{$y_op_val});
			}
		}
	}

	@include media('>=tablet', '<desktop-lg') {
		left: 0;
		top: 0;
		transform: none;
	}

	svg {
		display: block;
	}
}

// svg classes
.country {
	.pin,
	.pin-active {
		cursor: pointer;
		filter: url(#filterDropShadow);
	}

	.pin {
		display: block;
	}

	.pin-active {
		display: none;
	}

	&.active .pin {
		display: none;
	}

	&.active .pin-active {
		display: block;
	}

	@include media('<tablet') {
		.pin,
		.pin ~ .text {
			display: none;
		}

		&.active .text {
			display: block;
		}
	}

	.text {
		fill: #fff;
		filter: url(#filterDropShadow);
		font-family: inherit;
		font-size: 10px;
		font-weight: 100;
		text-transform: capitalize;
	}
}
</style>
