export const COUNTRIES_SVG_DATA = [
	{
		name: 'bulgaria',
		pin: {
			default: {
				x: 391.5,
				y: 463
			},
			active: {
				x: 387,
				y: 453
			}
		},
		text: {
			x: 391.5,
			y: 511.5
		}
	},
	{
		name: 'montenegro',
		pin: {
			default: {
				x: 281.5,
				y: 456
			},
			active: {
				x: 277,
				y: 446
			}
		},
		text: {
			x: 273,
			y: 505.5
		}
	},
	{
		name: 'serbia',
		pin: {
			default: {
				x: 319,
				y: 433.5
			},
			active: {
				x: 314.5,
				y: 423.5
			}
		},
		text: {
			x: 322.5,
			y: 482.7
		}
	},
	{
		name: 'croatia',
		pin: {
			default: {
				x: 227.5,
				y: 438.5
			},
			active: {
				x: 223,
				y: 428.5
			}
		},
		text: {
			x: 230,
			y: 487.8
		}
	},
	{
		name: 'albania',
		pin: {
			default: {
				x: 309.5,
				y: 523.5
			},
			active: {
				x: 305,
				y: 513.5
			}
		},
		text: {
			x: 311,
			y: 573
		}
	},
	{
		name: 'russia',
		pin: {
			default: {
				x: 645,
				y: 126
			},
			active: {
				x: 640.5,
				y: 116
			}
		},
		text: {
			x: 649,
			y: 175
		}
	},
	{
		name: 'hungary',
		pin: {
			default: {
				x: 276.5,
				y: 369
			},
			active: {
				x: 272,
				y: 359
			}
		},
		text: {
			x: 276,
			y: 419
		}
	},
	{
		name: 'slovenia',
		pin: {
			default: {
				x: 188.5,
				y: 390.5
			},
			active: {
				x: 184,
				y: 380.5
			}
		},
		text: {
			x: 187.5,
			y: 440
		}
	},
	{
		name: 'moldova',
		pin: {
			default: {
				x: 466.5,
				y: 375.5
			},
			active: {
				x: 462,
				y: 365.5
			}
		},
		text: {
			x: 466,
			y: 424.6
		}
	},
	{
		name: 'uzbekistan',
		pin: {
			default: {
				x: 991.5,
				y: 421.5
			},
			active: {
				x: 987,
				y: 411.5
			}
		},
		text: {
			x: 985.5,
			y: 470
		}
	},
	{
		name: 'ukraine',
		pin: {
			default: {
				x: 540.5,
				y: 312.5
			},
			active: {
				x: 536,
				y: 302.5
			}
		},
		text: {
			x: 541.5,
			y: 362
		}
	}
];
