import layoutBase from '../layout-base.js';
import Vue from 'vue';
import './layout.scss';
import mhap from '../../modules/MHAP/mhap';
import mvtu from '../../modules/MVTU/mvtu';
import '../../applications/countries/countries.js';
import videoPlayer from '../../common/components/video-player/video-player.vue';
import VueYouTubeEmbed from 'vue-youtube-embed';
import YoutubePLayer from '../../common/components/youtube-player/youtube-player.vue';
Vue.component('YoutubePlayer', YoutubePLayer);

Vue.use(VueYouTubeEmbed);

new Vue({ extends: layoutBase, components: { mvtu, videoPlayer, mhap } }).$mount('[data-layout-id="ANYIT-app"]');
